import React from "react";
import { gql, useQuery } from "@apollo/client";

import { EmergencyAlert as EmergencyAlertData } from "./__generated__/EmergencyAlert";

const GET_EMERGENCY_ALERT = gql`
  query EmergencyAlert {
    emergencyAlert {
      title
      level
      message
    }
  }
`;
const updateTimeFuzz = Math.floor(Math.random() * (5000 + 1)) + 2500;
interface Props {
  className?: string;
}

const CLASS_FOR_LEVEL = {
  warn: "bg-yellow-200",
  warning: "bg-yellow-200",
};

export default function EmergencyAlert({ className }: Props) {
  const { data } = useQuery<EmergencyAlertData>(GET_EMERGENCY_ALERT, {
    fetchPolicy: "cache-and-network",
    // Needed to make polling work.
    notifyOnNetworkStatusChange: true,
    pollInterval: window === undefined ? undefined : updateTimeFuzz,
  });

  const alert = data?.emergencyAlert;
  if (alert) {
    // @ts-ignore
    const classForLevel = CLASS_FOR_LEVEL[alert.level] || "";
    return (
      <section
        className={`${className} px-2 py-2 emergency-alert ${classForLevel}`}
      >
        <h2 className="text-4xl">{alert.title}</h2>
        <div className="text-2xl pt-2">{alert.message}</div>
      </section>
    );
  }

  return null;
}
