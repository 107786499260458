import React from "react";
import { DateTime } from "luxon";
import { GetArrivals_arrivals } from "./__generated__/GetArrivals";
import { timeFormat } from "./config";
import { OccupancyStatus } from "./__generated__/globalTypes";

function mins(currentTime: DateTime, dt: DateTime) {
  return Math.round(currentTime.until(dt).toDuration().as("minutes"));
}

class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${val}`);
  }
}

function mapOccupancyToLoadIndicator(
  occ: OccupancyStatus | null
): [string, JSX.Element | null] {
  if (!occ) {
    return ["", null];
  }

  const one = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
        clipRule="evenodd"
      />
    </svg>
  );

  const two = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
    </svg>
  );

  const three = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
    </svg>
  );

  switch (occ) {
    case OccupancyStatus.EMPTY:
      return ["Empty", one];
    case OccupancyStatus.MANY_SEATS_AVAILABLE:
      return ["Many seats available", one];
    case OccupancyStatus.FEW_SEATS_AVAILABLE:
      return ["Few seats available", one];
    case OccupancyStatus.STANDING_ROOM_ONLY:
      return ["Standing room only", two];
    case OccupancyStatus.CRUSHED_STANDING_ROOM_ONLY:
      return ["Crushed standing room only", three];
    case OccupancyStatus.FULL:
      return ["Full", three];
    case OccupancyStatus.NOT_ACCEPTING_PASSENGERS:
      return ["Not accepting passengers", null];
    default:
      throw new UnreachableCaseError(occ);
  }
}

function screenreader(currentTime: DateTime, arrival: GetArrivals_arrivals) {
  const dt = DateTime.fromISO(arrival.sortKey, { setZone: true });
  const m = mins(currentTime, dt);
  let strr = `${m} minutes`;
  if (m > 60) {
    const hrs = Math.floor(m / 60);
    const residual = m - 60 * hrs;
    if (residual === 0) {
      strr = `${hrs} hours`;
    } else {
      strr = `${hrs} hours and ${residual} minutes`;
    }
  }

  return `${arrival.type} ${strr}`;
}

function humanize(currentTime: DateTime, arrival: GetArrivals_arrivals) {
  const dt = DateTime.fromISO(arrival.sortKey, { setZone: true });
  const m = mins(currentTime, dt);
  if (m >= 60) {
    return dt.setZone(currentTime.zoneName).toFormat(timeFormat);
  }

  if (m <= 0) {
    return "Now";
  }

  return `${m} min${m !== 1 ? "s" : ""}`;
}

interface ArrivalRowProps {
  arrival: GetArrivals_arrivals;
  showStopInfo: boolean;
  currentTime: DateTime;
  showPlatformColumn: boolean;
}

export function LoadingArrivals(count: number, showPlatformColumn: boolean) {
  return Array.from(Array(count).keys()).map((x) => {
    return (
      <div
        key={`loading-${x}`}
        className="animate-pulse h-16 table-row trip border-b border-gray-400"
      >
        <div
          className="align-middle w-1/6 table-cell text-center number-highlight"
          aria-label="Route"
        >
          <div className="my-2 w-2/3 bg-gray-400 rounded h-16" />
        </div>
        <div className="align-middle table-cell mx-4 py-2 portrait:py-2 lg:py-0 text-highlight tracking-tighter leading-tight">
          <div className="my-2 text-xl lg:text-2xl font-semibold tracking-tighter rounded bg-gray-400 h-16 w-full"></div>
        </div>
        <div className="table-cell align-middle text-right number-highlight minutes">
          <div className="my-2 bg-gray-400 float-right w-2/3 rounded h-16"></div>
        </div>
        {showPlatformColumn && (
          <div className="table-cell align-middle text-right number-highlight">
            <div className="my-2 bg-gray-400 float-right w-2/3 rounded h-16"></div>
          </div>
        )}
      </div>
    );
  });
}

export default function ArrivalRow({
  arrival,
  currentTime,
  showStopInfo,
  showPlatformColumn,
}: ArrivalRowProps) {
  const [occupancyLabel, occupancyStatus] = mapOccupancyToLoadIndicator(
    arrival.vehicle?.occupancyStatus || null
  );
  return (
    <div
      key={`${arrival.route.id}-${arrival.stop.id}-${arrival.sortKey}`}
      className={`table-row trip border-b border-gray-400 ${arrival.type}`}
    >
      <div
        className="align-middle w-1/6 table-cell text-center number-highlight"
        aria-label="Route"
      >
        {arrival.route.shortName}
      </div>
      <div className="align-middle table-cell mx-4 py-2 portrait:py-2 lg:py-0 text-highlight tracking-tighter leading-tight">
        <div className="font-semibold ">{arrival.route.longName}</div>
        {arrival.trip.headsign && <div>to {arrival.trip.headsign}</div>}
        {occupancyStatus ? (
          <div className="right" role="img" aria-label={occupancyLabel}>
            {occupancyStatus}
          </div>
        ) : null}
        {showStopInfo && (
          <div className="lg:mt-1">
            {!showPlatformColumn && !arrival.stop.platformCode && (
              <>
                Stop: <strong>{arrival.stop.id}</strong>
              </>
            )}
            {!showPlatformColumn && arrival.stop.platformCode ? (
              <>
                {" "}
                (Platform <strong>{arrival.stop.platformCode}</strong>)
              </>
            ) : null}
          </div>
        )}
      </div>
      <div
        className="table-cell align-middle text-right number-highlight minutes"
        aria-label={screenreader(currentTime, arrival)}
      >
        {humanize(currentTime, arrival)}
      </div>
      {showPlatformColumn && (
        <div
          className="table-cell align-middle text-right number-highlight"
          aria-label="Platform"
        >
          {arrival.stop.platformCode}
        </div>
      )}
    </div>
  );
}
