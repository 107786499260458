import React from "react";

import Logo from "./Logo";
import { Link } from "react-router-dom";
interface Props {
  agencyName?: string;
  logoUrl: string;
  className?: string;
}

export default function TransitHeader({
  agencyName,
  logoUrl,
  className,
}: Props) {
  const name = agencyName || "transit agency";
  return (
    <section className={`h-full py-4 ${className}`}>
      <Link to="/" aria-label={`${name} logo`}>
        <Logo url={logoUrl} agencyName={name} />
      </Link>
    </section>
  );
}
