import React, { useState, useEffect } from "react";

function useNavigatorOffline() {
  let [value, setValue] = useState(!window.navigator.onLine);

  useEffect(() => {
    function handleStatusChange() {
      setValue(!window.navigator.onLine);
    }

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  return value;
}

type Props = {
  agencyName: string;
  url: string;
};

export default function Logo({ url, agencyName }: Props) {
  const isOffline = useNavigatorOffline();

  return (
    <img
      src={url}
      alt={`${agencyName} logo`}
      className={`object-scale-down object-left h-full ${
        isOffline ? "offline" : ""
      }`}
    />
  );
}
