import React from "react";

import NextBus from "./NextBus";
import { useParams, useLocation } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import {
  ConstructPageTitle,
  ConstructPageTitleVariables,
} from "./__generated__/ConstructPageTitle";
import { TransitAgencyConfig } from "./config";

const TITLE_QUERY = gql`
  query ConstructPageTitle($requestedStops: [ID]!) {
    stops(filter: { idIn: $requestedStops }) {
      id
      name
    }
    stations(filter: { idIn: $requestedStops }) {
      name
    }
  }
`;

type Props = {
  config: TransitAgencyConfig;
};

const useQueryParams = (queryParam: string) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam) || "";
};

export default function SchedulePage(props: Props) {
  let { stops: propsStops, station } = useParams();

  const stops = propsStops?.split(",") || [];
  const requestedStops = (station ? [station] : stops) || [];

  const { data } = useQuery<ConstructPageTitle, ConstructPageTitleVariables>(
    TITLE_QUERY,
    {
      variables: { requestedStops },
      fetchPolicy: "no-cache",
    }
  );
  let maxArrivals = Math.max(
    0,
    parseInt(useQueryParams("maxArrivals"), 10) || 0
  );
  let maxPages = Math.max(0, parseInt(useQueryParams("maxPages"), 10) || 0);
  let pageInterval = Math.max(
    500,
    parseInt(useQueryParams("pageInterval"), 10) || 500
  );

  let arbMessage = useQueryParams("arbMessage");

  const stopsData = data?.stops || [];
  const stationsData = data?.stations || [];
  let pageTitle = null;
  if (stopsData.length === 0 && stationsData.length === 0) {
    pageTitle = null;
  } else if (stopsData.length > 1) {
    pageTitle = `Stops ${stopsData.map((x) => `#${x.id}`).join(", ")}`;
  } else if (stationsData.length > 0) {
    pageTitle = stationsData[0].name;
  } else {
    pageTitle = `${stopsData[0].name} (Stop #${stopsData[0].id})`;
  }

  return (
    <section className="p-4 flex-grow grid gap-4 grid-cols-1 grid-rows-1 portrait:grid-cols-1">
      <Helmet>{pageTitle && <title>{pageTitle}</title>}</Helmet>
      <NextBus
        className="order-1 flex-auto"
        stops={stops}
        station={station}
        pageTitle={pageTitle}
        showPlatformColumn={props.config.showPlatformColumn || false}
        maxArrivals={maxArrivals}
        maxPages={maxPages}
        paginationInterval={pageInterval}
        arbMessage={arbMessage}
      />
    </section>
  );
}
