import React, { useMemo, useRef, useState, useEffect } from "react";
import { useQuery, gql, useReactiveVar } from "@apollo/client";
import useResizeObserver from "use-resize-observer";
import debounce from "lodash/debounce";

import { DateTime } from "luxon";
import { time } from "./store";
import { GetArrivals, GetArrivalsVariables } from "./__generated__/GetArrivals";
import ArrivalRow, { LoadingArrivals } from "./ArrivalRow";
import ErrorState from "./ErrorState";

const GET_ARRIVALS = gql`
  query GetArrivals($station: ID, $stops: [ID]!, $limit: Int) {
    arrivals(filter: { stops: $stops, station: $station }, limit: $limit) {
      stop {
        id
        platformCode
      }
      route {
        id
        shortName
        longName
      }
      trip {
        headsign
      }
      type: typeAsString
      sortKey: departure
      vehicle {
        occupancyStatus
      }
    }
  }
`;

// Value between -2500 and 2500 inclusive
const updateTimeFuzz = Math.floor(Math.random() * (5000 + 1)) - 2500;
const updateTime = 17500 + updateTimeFuzz;
interface Props {
  stops?: string[];
  station?: string | null;
  limit?: number;
  pageTitle?: string | null;
  className?: string;
  maxArrivals: number;
  maxPages: number;
  paginationInterval?: number;
  showPlatformColumn: boolean;
  arbMessage?: string;
}

export default function NextBus({
  stops,
  station,
  limit,
  className,
  pageTitle,
  maxArrivals,
  maxPages,
  paginationInterval = 10000,
  showPlatformColumn,
  arbMessage,
}: Props) {
  limit = Math.min(30, Math.max(1, limit || 30));
  const [pageNum, setPageNum] = useState<number>(0);
  const [dataMaxPages, setDataMaxPages] = useState<number>(maxPages);
  const [height, setHeight] = useState<number | undefined>();
  const hasMultipleStops = !!(station || (stops || []).length > 1);

  useEffect(() => {
    const interval = setInterval(() => {
      setPageNum((pageNum + 1) % dataMaxPages);
    }, paginationInterval);
    return () => clearInterval(interval);
  }, [dataMaxPages, pageNum, paginationInterval]);

  const table = useRef<HTMLTableElement>(null);
  const repage = useMemo(() => {
    return ({ height }: { height: number | undefined }) => {
      const tbl = table.current;
      if (!tbl || !height || maxArrivals !== 0) {
        return;
      }
      setHeight(height);

      const rows = Array.from(
        tbl.querySelectorAll(".table-row-group .table-row")
      );
      const headerHeight =
        tbl.querySelector(".table-header-group")?.clientHeight || 0;
      const legendHeight =
        tbl.parentElement?.querySelector(".legend")?.clientHeight || 0;
      rows.forEach((x) => {
        x.classList.remove("hidden");
      });
      let remainingHeight = height - headerHeight - legendHeight;
      let tripped = false;
      rows.forEach((x) => {
        if (remainingHeight > x.clientHeight && !tripped) {
          remainingHeight -= x.clientHeight;
          x.classList.remove("hidden");
        } else {
          tripped = true;
          x.classList.add("hidden");
        }
      });
    };
  }, [maxArrivals]);
  const { ref: resizeRef } = useResizeObserver<HTMLElement>({
    onResize: debounce(repage, 150),
  });

  const { loading, data, error } = useQuery<GetArrivals, GetArrivalsVariables>(
    GET_ARRIVALS,
    {
      variables: { limit, stops: stops || [], station },
      pollInterval: updateTime,
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setTimeout(() => repage({ height }), 25);
        if (maxPages !== 0 && maxArrivals !== 0) {
          const numArrivals = (data?.arrivals || []).length;
          setDataMaxPages(
            Math.max(
              1,
              Math.min(maxPages, Math.ceil(numArrivals / maxArrivals))
            )
          );
        }
      },
    }
  );
  const currentTime = DateTime.fromISO(useReactiveVar(time), { setZone: true });

  if (error) {
    console.error(error);
    return <ErrorState error={error} />;
  }

  const startArrival = maxArrivals === 0 ? 0 : pageNum * maxArrivals;
  const endArrival =
    (maxArrivals !== 0 && startArrival + maxArrivals) || undefined;
  const sortedArrivals = (data?.arrivals || [])
    .filter((arrival) => {
      return DateTime.fromISO(arrival.sortKey) >= currentTime;
    })
    .sort((arrivalA, arrivalB) => {
      const dateA = DateTime.fromISO(arrivalA.sortKey).toLocal();
      const dateB = DateTime.fromISO(arrivalB.sortKey).toLocal();
      const diff = dateA.diff(dateB);
      return diff.toObject()["milliseconds"] || 0;
    })
    .slice(startArrival, endArrival);

  const arrivals = (
    <div className={"table-row-group"}>
      {loading && sortedArrivals.length === 0
        ? LoadingArrivals(
            maxArrivals === 0 ? 10 : Math.min(maxArrivals, 10),
            showPlatformColumn
          )
        : sortedArrivals.map((arrival, idx) => (
            <ArrivalRow
              key={idx}
              arrival={arrival}
              showStopInfo={hasMultipleStops}
              currentTime={currentTime}
              showPlatformColumn={showPlatformColumn}
            />
          ))}
    </div>
  );

  return (
    <section ref={resizeRef} className={`${className}`}>
      <h1 className="sr-only">
        Upcoming departures {pageTitle ? `for ${pageTitle}` : null}
      </h1>
      <div
        ref={table}
        className={`table w-full border-collapse ${
          maxArrivals !== 0 && "h-5/6"
        }`}
      >
        <div className="table-header-group font-bold border-b border-gray-400">
          <div className="text-highlight table-row">
            <div className="table-cell">Route</div>
            <div className="table-cell">Destination</div>
            {/* `minutes` class here pads out the title to make room for the realtime icon */}
            <div
              className={`table-cell text-right minutes ${
                loading ? "animate-pulse" : ""
              }`}
            >
              Departing
            </div>
            {showPlatformColumn && (
              <div className="table-cell text-right">Platform</div>
            )}
          </div>
        </div>
        {arrivals}
      </div>
      {!loading && sortedArrivals.length === 0 ? (
        <div className="h-full w-full text-center pt-32">
          No arrivals found.
        </div>
      ) : (
        <div className="flex w-full justify-between">
          {maxArrivals !== 0 && (
            <div className="mx-4 text-highlight">
              Page {pageNum + 1} of {dataMaxPages}
            </div>
          )}
          {arbMessage && (
            <div className="mx-4 text-highlight">{arbMessage}</div>
          )}
          <div className="estimated legend mx-4 text-highlight">
            Real-time Departure
          </div>
        </div>
      )}
    </section>
  );
}
