import React, { useState, useEffect, useMemo } from "react";
import Select, {
  components,
  MultiValueProps,
  OptionProps,
  GroupBase,
} from "react-select";
import { QuickScore, ScoredObject } from "quick-score";

import { gql, useQuery } from "@apollo/client";
import {
  GetAllStopsAndStations,
  GetAllStopsAndStations_stations,
  GetAllStopsAndStations_stops,
} from "./__generated__/GetAllStopsAndStations";
import { useNavigate } from "react-router-dom";
import haversineDistance from "haversine-distance";
import useGeolocation from "./hooks/useGeolocation";

const GET_ALL_STOPS = gql`
  query GetAllStopsAndStations {
    stops(limit: 10000) {
      value: id
      label: name
      location {
        latitude
        longitude
      }
      parentStation
    }
    stations(limit: 10000) {
      value: id
      label: name
      location {
        latitude
        longitude
      }
      parentStation
    }
  }
`;

interface OptionType
  extends ScoredObject<
    GetAllStopsAndStations_stations | GetAllStopsAndStations_stops
  > {
  distance: number | null;
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const SEARCH_RESULT_LIMIT = 5;
const locationBasedSortScore: (result: OptionType) => number = (result) => {
  return (result.distance || 1) * (result.score || 1);
};

function mapSearchResultsToOptions<
  T extends GetAllStopsAndStations_stations | GetAllStopsAndStations_stops
>(
  results: ScoredObject<T>[],
  position: GeolocationPosition | null
): OptionType[] {
  // Make sure we're not processing an unbounded number of results.
  const options = results.slice(0, 5 * SEARCH_RESULT_LIMIT).map((x) => {
    return {
      ...x,
      value: x.item.value,
      label: x.item.label,
      distance: position
        ? haversineDistance(x.item.location, position.coords)
        : null,
    };
  });

  if (position) {
    options.sort((a, b) => {
      return locationBasedSortScore(a) - locationBasedSortScore(b);
    });
  }

  return options.slice(0, SEARCH_RESULT_LIMIT);
}

function humanMetricDistance(meters: number): string {
  if (meters < 5) {
    return `nearby`;
  } else if (meters < 100) {
    return `${Math.round(meters)} m`;
  } else if (meters < 1000) {
    return `${Math.round(meters / 10) * 10} m`;
  } else if (meters < 10000) {
    // Intentionally not dividing by 1000 -- we want results like "4.2 km"
    return `${Math.round(meters / 100) / 10} km`;
  } else {
    return `${Math.round(meters / 1000)} km`;
  }
}

function highlight(string: string, matches: [number, number][]) {
  const substrings = [];
  let previousEnd = 0;

  for (let [start, end] of matches) {
    const prefix = (
      <span aria-hidden>{string.substring(previousEnd, start)}</span>
    );
    const match = (
      <mark aria-hidden className="bg-transparent font-bold">
        {string.substring(start, end)}
      </mark>
    );

    substrings.push(prefix, match);
    previousEnd = end;
  }

  substrings.push(<span aria-hidden>{string.substring(previousEnd)}</span>);

  return <span>{React.Children.toArray(substrings)}</span>;
}

const MultiValue = (props: MultiValueProps<OptionType>) => (
  <components.MultiValue {...props}>
    {props.data.item.label}
    {props.data.item.__typename === "Stop" && ` (#${props.data.item.value})`}
  </components.MultiValue>
);

const Option = (props: OptionProps<OptionType, true>) => {
  const ariaLabel = `${props.data.item.label}${
    props.data.item.__typename === "Stop" && ` Stop ${props.data.item.value}`
  }`;

  return (
    <components.Option {...props} aria-label={ariaLabel}>
      {highlight(props.data.item.label, props.data.matches["label"] || [])}
      {props.data.item.__typename === "Stop" && (
        <>
          {" "}
          (Stop #
          {highlight(
            props.data.item.value || "",
            props.data.matches["value"] || []
          )}
          )
        </>
      )}
      {props.data.distance && (
        <span className="float-right text-sm text-gray-700">
          {humanMetricDistance(props.data.distance)}
        </span>
      )}
    </components.Option>
  );
};

const positionOpts = {
  enableHighAccuracy: false,
  timeout: 5000,
  maximumAge: 60 * 1000,
};

export default function SelectorPage() {
  const navigate = useNavigate();
  const { data, loading } = useQuery<GetAllStopsAndStations>(GET_ALL_STOPS);

  const stopSearcher = useMemo(
    () => new QuickScore(data?.stops || [], { keys: ["value", "label"] }),
    [data?.stops]
  );

  const stationSearcher = useMemo(
    () => new QuickScore(data?.stations || [], { keys: ["label"] }),
    [data?.stations]
  );

  const geolocation = useGeolocation(positionOpts);

  const [selectedStops, setSelectedStops] = useState<string[]>([]);
  const [input, setInput] = useState<string>("");
  const [options, setOptions] = useState<GroupBase<OptionType>[]>();

  useEffect(() => {
    const strippedInput = input.trim();
    const geolocationResponse =
      geolocation && !geolocation.isError ? geolocation.response : null;
    const stationResults = mapSearchResultsToOptions(
      stationSearcher.search(strippedInput),
      geolocationResponse
    );

    const stopResults = mapSearchResultsToOptions(
      stopSearcher.search(strippedInput),
      geolocationResponse
    );

    const mappedOptions: GroupBase<OptionType>[] = [];
    if (stationResults.length > 0) {
      mappedOptions.push({ label: "Stations", options: stationResults });
    }

    if (stopResults.length > 0 && strippedInput.length > 0) {
      mappedOptions.push({
        label: "Stops",
        options: stopResults,
      });
    }
    setOptions(mappedOptions);
  }, [stationSearcher, stopSearcher, input, geolocation]);

  return (
    <section className="flex justify-center my-4 flex-auto">
      <h1 className="sr-only">Stop Selector</h1>
      <section className="stopselect order-1 md:min-w-1/2 md:max-w-3/4 min-w-3/4 max-w-7/8">
        <Select
          aria-label="Search for a stop"
          components={{ MultiValue, Option }}
          isSearchable
          isLoading={loading}
          isMulti
          // Disable react-select's built-in option filtering, since that
          // affects displaying fuzzy matching results.
          filterOption={null}
          options={options}
          noOptionsMessage={() => "No stops found"}
          onChange={(val) => {
            const stops = [val].filter(notEmpty).flat();
            const station = stops.find((x) => x.item.__typename === "Station");
            if (station) {
              navigate?.(`/station/${station.item.value}`);
            } else {
              setSelectedStops(stops.map((x) => x.item.value).filter(notEmpty));
            }
          }}
          styles={{
            placeholder: (provided, _state) => {
              const color = "#595959";

              return { ...provided, color };
            },
          }}
          onInputChange={setInput}
          placeholder="Search for a stop"
        />
        <span className="inline-flex w-full">
          <span className="inline-flex rounded-md shadow-sm justify-center mx-auto my-4">
            <button
              onClick={() => {
                const stopIds = selectedStops.join(",");
                navigate?.(`/stops/${stopIds}`);
              }}
              disabled={selectedStops.length === 0}
              type="button"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brandPrimary hover:bg-brandPrimaryLight focus:outline-none focus:border-brandPrimaryDark focus:ring-brandPrimary active:bg-brandPrimaryDark transition ease-in-out duration-150"
            >
              GO
            </button>
          </span>
        </span>
      </section>
    </section>
  );
}
