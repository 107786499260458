import React from "react";
import logo from "../assets/york/logo.png";
import { TransitAgencyConfig } from "../config";

export const headConfig = <>{/* TODO */}</>;

const config: TransitAgencyConfig = {
  headConfig,
  appName: "YRT/Viva TODO APPNAME",
  logoUrl: logo,
  // twitterUsername: "YRTViva",
  showPlatformColumn: true,
};
export default config;
