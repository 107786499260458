import { useEffect, useState } from "react";

type GeolocationResponse =
  | {
      isError: true;
      response: GeolocationPositionError;
    }
  | {
      isError: false;
      response: GeolocationPosition;
    };

const useGeolocation = (positionOpts: PositionOptions = {}) => {
  const [response, setResponse] = useState<GeolocationResponse | null>(null);

  useEffect(() => {
    let didCancel: boolean = false;
    const updateResponse = (response: GeolocationPosition) => {
      if (!didCancel) {
        setResponse({ isError: false, response });
      }
    };
    const updateError = (response: GeolocationPositionError) => {
      if (!didCancel) {
        setResponse({ isError: true, response });
      }
    };

    let watchId: number;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(updateResponse, updateError);
      watchId = navigator.geolocation.watchPosition(
        updateResponse,
        updateError,
        positionOpts
      );
    }
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
      didCancel = true;
    };
  }, [positionOpts]);

  return response;
};

export default useGeolocation;
